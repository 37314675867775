<template>
  <Observer name="fade-up" ref="section" :section="true" @enter.once="callGTM('eePromoView')" class="-visibility-auto">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 text-center">
          <i class="icon-star"></i>
          <h2 class="h4 -upper" v-if="item.title" v-text="item.title"></h2>
          <div class="h6 -upper" v-if="item.subtitle" v-text="item.subtitle"></div>
          <p class="wysiwyg" v-if="item.text" v-html="item.text"></p>
          <router-link :to="localizedUrl + item.ctaLink" class="btn -large" v-if="item.ctaLink" v-text="item.ctaText" @click.native="callGTM('eePromoClick', item, -1)"></router-link>
        </div>
        <div class="col-12 col-lg-8" :style="`height: ${slideHeight}px`">
          <div ref="overflow" class="overflow" :style="`width: ${overflowWidth}px`">
            <ul ref="slider" class="slider">
              <li v-for="(slide, key) in slideItems" :style="`width: ${slideWidth}px; height: ${slideHeight}px`" class="slide" :key="key">
                <router-link :to="localizedUrl + slide.link" v-if="slide.link" class="-layer" :class="{'-darker-layer' : slide.title }" @click.native="callGTM('eePromoClick', slide, key)">
                  <lazy-image class-name="slider-limited-image" ref="picture" :emptyAlt="slide.title" :item="slide.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
                  <h3 class="h3 -upper" v-if="slide.title" v-text="slide.title"></h3>
                  <p class="wysiwyg -upper" v-if="slide.subtitle" v-html="slide.subtitle"></p>
                </router-link>
                <div v-else :class="{ '-layer': slide.title || slide.subtitle}">
                  <lazy-image class-name="slider-limited-image" ref="picture" :emptyAlt="slide.title" :item="slide.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
                  <h3 class="h3 -upper" v-if="slide.title" v-text="slide.title"></h3>
                  <p class="wysiwyg -upper" v-if="slide.subtitle" v-html="slide.subtitle"></p>
                </div>
              </li>
            </ul>
          </div>
          <div class="nav" v-if="items.length > 1" :style="`width: ${overflowWidth}px`">
            <button type="button" class="back" @mouseover="backOver" @mouseleave="leave" tabindex="-1"
                    :class="{'is-disabled': progressBarLeft <= 0}" :aria-label="$t('Slide to left')"></button>
            <button type="button" class="next" @mouseover="nextOver" @mouseleave="leave" tabindex="-1"
                    :class="{'is-disabled': progressBarLeft >= (progressBarWidth * items.length - progressBarWidth) - 0.1}"
                    :aria-label="$t('Slide to right')"></button>
          </div>
          <div ref="progress" class="progress" v-if="items.length > 1">
            <NoSSR>
              <drag-it-dude ref="drag" @dragging="progressDragging">
                <div ref="bar" class="progress-bar" :style="`width: ${progressBarWidth}px;`"></div>
              </drag-it-dude>
            </NoSSR>
          </div>
        </div>
      </div>
    </div>
  </Observer>
</template>

<script>
import {currentStoreView} from '@vue-storefront/core/lib/multistore'
import {ReadyHook} from 'vue-asyncready'
import DragItDude from 'vue-drag-it-dude'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import LazyImage from './utils/LazyImage'
import { getRouteName } from '../utilities'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'slider_limited_edition',
  data() {
    return {
      overflowWidth: 0,
      slideWidth: 0,
      slideHeight: 0,
      progressBarWidth: 0,
      progressBarLeft: 0,
      interval: null,
      vitesse: 0,
      once: false
    }
  },
  components: {
    LazyImage,
    NoSSR,
    DragItDude
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    slideItems() {
      return this.items.map(item => {
        return {
          link: item.link,
          title: item.title,
          subtitle: item.subtitle,
          picture: {
            url: item.image.url,
            lowDefinitionUrl: ImageManager.getLowDefinition(item.image.url, 200, 0.7991452991),
            srcset: ImageManager.getSrcSet(item.image.url, 1000, 100, 100, 0.7991452991),
            alt: item.image.alt || item.title,
            width: item.image.width,
            height: item.image.height
          }
        }
      })
    },
    pictureSizes() {
      return '(min-width: 768px) 64vw, (min-width: 992px) 46vw, 80vw'
    }
  },
  mounted() {
    this.sizeCalc()
    window.addEventListener('resize', this.onResize)
    this.$refs.overflow.addEventListener('scroll', this.progressPosition)
  },
  methods: {
    onResize() {
      this.sizeCalc()
      this.progressPosition()
    },
    sizeCalc() {
      if (this.$refs.overflow) {
        this.overflowWidth = document.body.clientWidth - this.$refs.overflow.getBoundingClientRect().left
        let container = window.innerWidth > 1200 ? 1200 : window.innerWidth
        if (window.innerWidth > 992) {
          this.slideWidth = container / 2 - 40
          this.slideHeight = this.slideWidth * 80 / 100
        } else if (window.innerWidth > 767) {
          this.slideWidth = container / 1.5 - 20
          this.slideHeight = this.slideWidth * 80 / 100
        } else {
          this.slideWidth = container / 1.2 - 20
          this.slideHeight = this.slideWidth + 0
        }
      }
      this.progressBarWidth = this.$refs.progress.offsetWidth * (100 / this.items.length) / 100
    },
    progressPosition() {
      this.progressBarLeft = this.$refs.overflow.scrollLeft / (this.$refs.slider.offsetWidth - this.$refs.overflow.offsetWidth) * (this.$refs.progress.offsetWidth - this.progressBarWidth)
      this.$refs.drag.$el.style.left = this.progressBarLeft + 'px'
      if (this.progressBarLeft <= 0 || this.progressBarLeft >= (this.progressBarWidth * this.items.length - this.progressBarWidth) - 0.1) {
        this.leave()
      }
    },
    progressDragging() {
      if (this.$refs.drag && this.$refs.drag.$el && this.$refs.drag.$el) {
        this.$refs.overflow.scrollLeft = this.$refs.drag.$el.offsetLeft / (this.$refs.progress.offsetWidth - this.progressBarWidth) * (this.$refs.slider.offsetWidth - this.$refs.overflow.offsetWidth)
      }
    },
    backOver() {
      clearInterval(this.interval)
      let vitesse = this.$refs.slider.offsetWidth / this.overflowWidth
      this.interval = setInterval(() => {
        this.$refs.overflow.scrollLeft -= 6 * vitesse
        this.progressPosition()
      }, 1)
    },
    nextOver() {
      clearInterval(this.interval)
      let vitesse = this.$refs.slider.offsetWidth / this.overflowWidth
      this.interval = setInterval(() => {
        this.$refs.overflow.scrollLeft += 6 * vitesse
        this.progressPosition()
      }, 1)
    },
    leave() {
      clearInterval(this.interval)
      this.interval = null
    },
    callGTM(event, item, index) {
      if (this.$refs.section.getRootElement().classList.contains('-visibility-auto')) {
        this.$nextTick(() => {
          this.$refs.section.getRootElement().classList.remove('-visibility-auto')
        })
      }
      if (getRouteName(this.$route.name) === 'home') {
        let data = {
          event: event,
          promotions: this.slideItems.map((item, i) => {
            return {
              creative: 'slider',
              id: 'sliderLimitedEdition_' + this.pagePosition,
              name: (item.subtitle ? item.title + ' - ' + item.subtitle : item.title) || 'Slider Limited Edition',
              position: i + 1
            }
          })
        }
        if (item) {
          data.nextPageVisited = item.ctaLink ? this.localizedUrl + item.ctaLink : this.localizedUrl + item.link
          data.promotions = [{
            creative: 'slider',
            id: 'sliderLimitedEdition_' + this.pagePosition,
            name: (item.subtitle ? item.title + ' - ' + item.subtitle : item.title) || 'Slider Limited Edition',
            position: index + 1
          }]
          this.$gtm.trackEvent(data)
        } else {
          if (!this.once) {
            this.once = true
            this.$gtm.trackEvent(data)
          }
        }
      }
    }
  },
  destroyed: function () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  section {
    position: relative;
    margin: 10rem 0 18rem;

    @include bp(max lg) {
      margin: 10rem 0;
    }

    &.obs-fade-up {
      opacity: 1;
      transform: translateY(0);

      .h4, .h6, .wysiwyg, .btn {
        transform: translateY(8rem);
        opacity: 0;
      }
      .icon-star {
        opacity: 0;
      }
    }

    .icon-star {
      @include appear();
    }
    .h4 {
      display:inline-block;
      @include appear();
    }
    .h6 {
      @include appear(100ms);
    }
    .wysiwyg {
      @include appear(200ms);
    }
    .btn {
      @include appear(400ms);
    }

    .overflow {
      display: flex;
      height: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      margin-left: 4rem;
      position: relative;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background-color: #DFDFDF;
      }

      &::-webkit-scrollbar-thumb {
        background: #262626;
        border-radius: 2px;
      }

      @include bp(max lg) {
        margin-left: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .slider {
      display: block;
      white-space: nowrap;
      margin: 0;
      padding: 0;
      list-style: none;

      @include bp(max lg) {
        padding-left: 4rem;
        padding-right: 2rem;
      }

      @include bp(max lg) {
        padding-left: 2rem;
        padding-right: 0;
      }
    }

    .col-lg-4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--zenith-black);

      i {
        font-size: em(26);
        margin-bottom: 2rem;

        @include bp(max lg) {
          margin-bottom: 1rem;
        }
      }

      h2 {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .h6 {
        color: var(--zenith-component-slider-limited-edition-subtitle);
        margin-bottom: 4rem;

        @include bp(max lg) {
          margin-bottom: 2rem;
        }
      }

      .wysiwyg {
        font-size: em(16);
        letter-spacing: em(.2, 16);
        margin-bottom: 5rem;
        color: var(--zenith-component-slider-limited-edition-text);

        @include bp(max lg) {
          margin-bottom: 3rem;
        }
      }

      @include bp(max lg) {
        .btn {
          margin-bottom: 6rem;
        }
      }

    }

    .col-lg-8 {
      align-self: center;

      @include bp(max lg) {
        padding: 0;
        margin: 0 -2.5rem;
      }
      @include bp(max md) {
        margin: 0;
      }

      &:hover .nav {
        opacity: 1;
      }
    }

    .slide {
      position: relative;
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;
      margin-right: 2rem;

      a, & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-color: transparent;
        white-space: normal;
        text-align: center;
        padding: 4rem;
        border-radius: .5rem;
        overflow: hidden;

        &.-darker-layer {
          &:before {
            background: rgba(0, 0, 0, 0.6);
          }
        }

        @include bp(max md) {
          justify-content: flex-end;
          padding: 2rem;
        }

        .slider-limited-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          width: 100%;
          height: 100%;
          transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);

          /deep/ img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .h3 {
          position: relative;
          z-index: 20;
          display: block;
          color: var(--zenith-component-slider-limited-edition-image-text);
          margin-bottom: 1rem;
          // transition: all 0.2s cubic-bezier(.075, .82, .165, 1);
          transform: translateY(0);
          @include appearTitle(0ms);

          @include bp(max md) {
            font-size: em(18);
            letter-spacing: em(.2, 18);
            margin-bottom: .5rem;
          }
        }

        .wysiwyg {
          position: relative;
          z-index: 20;
          display: block;
          color: var(--zenith-component-slider-limited-edition-image-text);
          font-size: em(20);
          letter-spacing: em(2, 20);
          // transition: all 0.2s cubic-bezier(.075, .82, .165, 1);
          transform: translateY(0);
          @include appearTitle(300ms);

          @include bp(max md) {
            margin-bottom: 2rem;
            font-size: em(16);
            letter-spacing: em(1, 16);
          }
        }
      }

      a {
        &:focus {
          border: 1px dashed #383838;
        }
        &:hover, &:focus, &:active {

          &:before {
            opacity: 0;
          }

          .slider-limited-image {
            transform: translate(-50%, -50%) scale(1.1);
          }

          .h3 {
            opacity: 0;
            transform: translateY(3rem);
            @include appearTitle(100ms, 0ms, 800ms, 200ms);
          }

          .wysiwyg {
            opacity: 0;
            transform: translateY(3rem);
            @include appearTitle(0ms, 0ms, 800ms, 200ms);
          }
        }
      }
    }
  }

  .nav {
    z-index: 50;
    position: absolute;
    height: 100%;
    top: 0;
    left: 5.5rem;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    @include bp(max lg) {
      left: 0;
    }
    @include bp(max md) {
      display: none;
    }

    button {
      position: absolute;
      pointer-events: all;
      top: 50%;
      opacity: 0.5;
      outline: none;
      padding: 1.3rem;
      border: none;
      border-radius: 100%;
      background: #f8f8f8;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);

      &:before {
        display: block;
        font-family: 'zenith', sans-serif !important;
        speak: none;
        font-size: em(20);
        line-height: 2rem;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.is-disabled {
        opacity: 0 !important;
        pointer-events: none;
      }

      &.back {
        left: 4rem;
        &:before {
          content: "\e903";
        }
      }
      &.next {
        right: 4rem;
        &:before {
          content: "\e925";
        }
      }

      &:hover,
      &:active,
      &:focus {
        opacity: 1;

        &.back:before {
          @include pillPrevHover();
        }
        &.next:before {
          @include pillNextHover();
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .progress {
    position: relative;
    height: 12rem;
    width: calc(100% - 4rem);
    margin-left: 4rem;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: .2rem;
      border-radius: .2rem;
      transform: translateY(-50%);
      background: var(--zenith-component-slider-limited-progress-background);
    }

    &-bar {
      position: relative;
      cursor: pointer;
      height: 12rem;
      border-radius: .2rem;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: .2rem;
        border-radius: .2rem;
        transform: translateY(-50%);
        background: var(--zenith-black);
      }
    }

    @include bp(max md) {
      width: calc(100% - 4rem);
      margin-left: 2rem;
      margin-top: 2rem;
      height: 6rem;
      &-bar {
        height: 6rem;
      }
    }
  }
</style>
